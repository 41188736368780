export * from './common/firstLetters.js';
export * from './promisifyQuery';
import {toFixed} from "ol/math";

export const getTokenId = () => localStorage.getItem('tokenId');

export const getMapFromList = (array = [], key) =>
  array.reduce((acc, cur) => {
    acc[cur[key]] = cur;
    return acc;
  }, {});

export const getNodeId = encodedNodeId => {
  try {
    return JSON.parse(atob(encodedNodeId)).pop();
  } catch {
    console.warn("Can't decode node id");
    return undefined;
  }
};

export const convertDate = date => {
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  }).format(new Date(date));
};

export const sortByDate = (a, b) => {
  return new Date(b) - new Date(a);
};

export const formatCoordinates = ({ lat, lon, alt }, skipAlt = true) => {
  return `[${toFixed(lon, 6)}; ${toFixed(lat, 6)}${!skipAlt ? '; ' + toFixed(alt, 6) : ''}]`;
};

export const trimFromStart = (sourceString, trimString) => {
  const regExp = new RegExp(`^${trimString}`);
  return sourceString.replace(regExp, '');
};

export const formatNumber = (number, maxFractionDigits) =>
  new Intl.NumberFormat(navigator.language || navigator.languages[0], {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxFractionDigits
  }).format(Number(number));

export const toISOStringWithTimezone = date => {
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? '+' : '-';
  const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    diff +
    pad(tzOffset / 60) +
    ':' +
    pad(tzOffset % 60)
  );
};

export const getDateWithTimeZone = timeZone => {
  const date = new Date();
  date.setTime(date.getTime() + timeZone * 60 * 1000);
  return date;
};

export const getEmptyString = value =>
  value === undefined || value === null ? 'n/a' : value;
