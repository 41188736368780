import Vue from 'vue';

import './registerServiceWorker';
import { vuetify } from './registerPlugins';
import { testidPlugin } from './plugins/testid-plugin'

import './components';
import router from './router';

import Root from './Root.vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { apiClient } from './provider';
import { useMenuProvider } from '@/layouts/menu/useMenu';
import { usePopupProvider } from '@/compositions/popup';
import { useProgramSettingsProvider } from '@/modules/account/useProgramSettings';
import { useObjectsProvider } from '@/modules/objects/compositions/objects';
import { usePlansProvider } from '@/modules/plans/compositions/plans';
import { useLandmarksProvider } from '@/modules/landmarks/compositions/landmarks';
import { useGeozonesProvider } from '@/modules/geozones/compositions/geozones';
import { useGeotagsProvider } from '@/modules/geotags/compositions/geotags';
import { useObjectGroupsProvider } from '@/modules/object-groups/compositions/object-groups';
import { useTypesProvider } from '@/modules/object-types/compositions/types';
import { useConnectionsProvider } from '@/modules/connections/compositions/connections';
import { useSnackBarProvider } from '@/compositions/snackBar';
import { useMapProvider } from '@/compositions/map';
import { provide } from 'vue-demi';

Vue.config.productionTip = false;
Vue.use(testidPlugin)

new Vue({
  router,
  vuetify,
  setup(props, context) {
    provide(DefaultApolloClient, apiClient);
    useProgramSettingsProvider();
    useMapProvider();
    useGeotagsProvider();
    useObjectGroupsProvider();
    useObjectsProvider();
    usePopupProvider();
    useMenuProvider(context);
    useSnackBarProvider();
    usePlansProvider();
    useLandmarksProvider();
    useGeozonesProvider();
    useTypesProvider();
    useConnectionsProvider();
  },
  render: h => h(Root)
}).$mount('#app');
