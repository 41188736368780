import { apiClient } from '@/provider';

import objectScheme from './object.graphql';

export default {
  async fetch(id, options = {}) {
    const res = await apiClient.query({
      query: objectScheme.fetch,
      variables: {
        id
      },
      ...options
    });
    return res.data.object;
  },

  enable(id) {
    return apiClient.mutate({
      mutation: objectScheme.enable,
      variables: {
        id
      }
    });
  },

  disable(id) {
    return apiClient.mutate({
      mutation: objectScheme.disable,
      variables: {
        id
      }
    });
  },

  setMuted(id, muted) {
    return apiClient.mutate({
      mutation: objectScheme.setMuted,
      variables: {
        id,
        muted
      }
    });
  },

  update(id, patch) {
    return apiClient.mutate({
      mutation: objectScheme.update,
      variables: {
        input: {
          id,
          patch: {
            ...patch
          }
        }
      }
    });
  },

  unlink(relationId) {
    return apiClient.mutate({
      mutation: objectScheme.unlinkObjectsToObject,
      variables: {
        input: {
          id: relationId
        }
      }
    });
  },

  unlinkByObjectIds(object1Id, object2Id) {
    return apiClient.mutate({
      mutation: objectScheme.unlinkByObjectId1AndObjectId2,
      variables: {
        input: {
          object1Id,
          object2Id
        }
      }
    });
  },

  link(sourceObjectId, linkingObjectId) {
    return apiClient.mutate({
      mutation: objectScheme.linkObjectsToObject,
      variables: {
        input: {
          objectsToObject: {
            object1Id: sourceObjectId,
            object2Id: linkingObjectId
          }
        }
      },
      errorPolicy: 'none'
    });
  },

  delete(id) {
    return apiClient.mutate({
      mutation: objectScheme.delete,
      variables: {
        id
      }
    });
  },

  massiveDelete(ids) {
    return apiClient.mutate({
      mutation: objectScheme.massiveDelete,
      variables: {
        input: {
          mnPatch: ids
        }
      }
    });
  },

  massiveUpdate(data) {
    return apiClient.mutate({
      mutation: objectScheme.massiveUpdate,
      variables: {
        input: {
          mnPatch: data
        }
      }
    });
  },

  create({ name = '', description = '', enabled = true, schemaId }) {
    return apiClient.mutate({
      mutation: objectScheme.create,
      variables: {
        input: {
          object: {
            name,
            description,
            enabled,
            schemaId
          }
        }
      }
    });
  },

  updateObjectWithProperties(input) {
    return apiClient.mutate({
      mutation: objectScheme.updateObjectWithProperties,
      variables: {
        input
      }
    });
  },

  linkObjectsToObjects(sourceIds, destinationIds) {
    return apiClient.mutate({
      mutation: objectScheme.linkObjectsToObjects,
      variables: {
        input: {
          sourceIds,
          destinationIds
        }
      }
    });
  }
};
